import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../firebase";
import { LogDiscord } from "../../utils/LogDiscord";

export const addReservationToFirestore = async (data: unknown) => {
  try {
    const res = await addDoc(collection(db, "appointments"), data);
    return res;
  } catch (error) {
    console.error("Error adding document: ", error);
    LogDiscord(
      "error fonction addReservationToFirestore : " + error,
      "content.js"
    );
    const errorMessage = ["```json", JSON.stringify(data), "```"].join(" ");
    LogDiscord(errorMessage, "content.js");
  }
};
