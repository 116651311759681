import { Transition } from "@headlessui/react";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { CheckCircle, X } from "lucide-react";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { useAuth } from "../contexts/AuthContext";

const ProfilPage = () => {
  // get Client data from user email
  const [clientData, setClientData] = useState({
    id: "",
    firstname: "",
    lastname: "",
    email: "",
    country: "",
    adress: "",
    birthdate: "",
    city: "",
    zipcode: "",
  });
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (currentUser && currentUser.email) {
      getClientData(currentUser.email);
    } else {
      navigate("/login");
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClientData({ ...clientData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    try {
      if (!clientData.id) {
        return;
      }
      if (!clientData.birthdate) {
        clientData.birthdate = "";
      }

      //clientData.birthdate = format(new Date(clientData.birthdate), "dd-MM-yyyy");
      updateDoc(doc(db, "clients", clientData.id), {
        ...clientData,
      })
        .then(() => {
          setMessage("Enregistrement réussi!");
          setShow(true);
        })
        .catch(() => {
          setMessage("Erreur lors de l'enregistrement des données");
          setShow(true);
        });
    } catch (error) {
      setMessage("Erreur lors de l'enregistrement des données");
    }
  };

  const getClientData = async (email: string) => {
    const clientCol = collection(db, "clients");
    const q = query(clientCol, where("email", "==", email));
    const docSnap = await getDocs(q);

    if (docSnap.empty) {
      console.log("No matching documents.");
      return;
    } else {
      docSnap.forEach((doc) => {
        const data = doc.data();
        const newData = {
          firstname: data.firstname || "",
          lastname: data.lastname || "",
          email: data.email || "",
          country: data.country || "",
          adress: data.adress || "",
          birthdate: data.birthdate,
          city: data.city || "",
          zipcode: data.zipcode || "",
          id: doc.id,
          // ajoutez d'autres champs si nécessaire
        };

        setClientData(newData);
      });
    }
  };
  return (
    <div className="w-10/12 mx-auto p-4">
      <form>
        <div className="space-y-12">
          <div className=" pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Informations Personnelle
            </h2>
            {message !== "" && (
              <div
                aria-live="assertive"
                className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
              >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                  {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                  <Transition
                    show={show}
                    as={Fragment}
                    enter="transform ease-out duration-300 transition"
                    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="p-4">
                        <div className="flex items-start">
                          <div className="flex-shrink-0">
                            <CheckCircle
                              className="h-6 w-6 text-green-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3 w-0 flex-1 pt-0.5">
                            <p className="text-sm font-medium text-gray-900">
                              {message}
                            </p>
                          </div>
                          <div className="ml-4 flex flex-shrink-0">
                            <button
                              type="button"
                              className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => {
                                setShow(false);
                              }}
                            >
                              <span className="sr-only">Close</span>
                              <X className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Transition>
                </div>
              </div>
            )}
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
              <div className="md:col-span-3">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Prénom
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="firstname"
                    id="firstname"
                    value={clientData.firstname}
                    onChange={handleChange}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-900 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="md:col-span-3">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Nom
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="lastname"
                    id="lastname"
                    value={clientData.lastname}
                    onChange={handleChange}
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-900 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="md:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={clientData.email}
                    onChange={handleChange}
                    autoComplete="email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-900 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="md:col-span-3">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Pays
                </label>
                <div className="mt-2">
                  <input
                    id="country"
                    name="country"
                    type="text"
                    value={clientData.country}
                    onChange={handleChange}
                    autoComplete="country"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-900 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="md:col-span-3">
                <label
                  htmlFor="adress"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Addresse
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="adress"
                    id="adress"
                    value={clientData.adress}
                    onChange={handleChange}
                    autoComplete="adress"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-900 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="md:col-span-3">
                <label
                  htmlFor="street-address"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  date de naissance
                </label>
                <div className="mt-2">
                  <input
                    type="date"
                    name="birthdate"
                    id="birthdate"
                    value={clientData.birthdate}
                    onChange={handleChange}
                    autoComplete="address"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-900 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="md:col-span-3">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Ville
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="city"
                    id="city"
                    value={clientData.city}
                    onChange={handleChange}
                    autoComplete="address-level2"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-900 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="md:col-span-3">
                <label
                  htmlFor="postal-code"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Code postal
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="zipcode"
                    id="zipcode"
                    value={clientData.zipcode}
                    onChange={handleChange}
                    autoComplete="zipcode"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              {/* <div className="sm:col-span-3">
                <SwitchComponent
                  label="SMS"
                  value={clientData.smsReminders}
                  description="Recevoir des SMS"
                  change={(b) =>
                    setClientData({
                      ...clientData,
                      smsReminders: b,
                    })
                  }
                />
              </div>
              <div className="sm:col-span-3">
                <SwitchComponent
                  label="Email"
                  value={clientData.emailPromotions}
                  change={(b) =>
                    setClientData({
                      ...clientData,
                      emailPromotions: b,
                    })
                  }
                  description="Recevoir des emails"
                />
              </div> */}
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Annuler
          </button>
          <button
            type="submit"
            className="rounded-md bg-slate-950 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-900"
            onClick={handleSubmit}
          >
            Enregistrer
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProfilPage;
