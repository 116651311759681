import { useEffect } from "react";
import { Outlet, Route, Routes, useSearchParams } from "react-router-dom";
import Footer from "./components/Footer";
import ForgotPassword from "./components/ForgotPassword";
import NavBar from "./components/NavBar";
import ReservationPage from "./components/ReservationPage";
import ServiceDetails from "./components/ServiceDetails";
import BlogModule from "./pages/BlogModule";
import CancelledAppt from "./pages/CancelledAppt";
import ContactPage from "./pages/ContactPage";
import Formulaires from "./pages/FormsPage";
import GetReview from "./pages/GetReview";
import Gift from "./pages/GiftPage";
import Home from "./pages/Home";
import LivraisonPage from "./pages/LivraisonPage";
import NewLoginPage from "./pages/LoginPage";
import MyAppts from "./pages/MyAppts";
import NotFoundPage from "./pages/NotFoundPage";
import OrdersPage from "./pages/OrdersPage";
import PagesBlog from "./pages/PageBlog";
import PagePrestataire from "./pages/PagePrestataire";
import PaymentPage from "./pages/PaymentPage";
import ProductPage from "./pages/ProductPage";
import ProfilPage from "./pages/ProfilPage";
import ServicePage from "./pages/ServicePage";
import ShopPage from "./pages/ShopPage";
import SuccessAppt from "./pages/SuccessAppt";
import SuccessGift from "./pages/SuccessGift";
import SuccessPage from "./pages/SuccessPage";
import TestPage from "./pages/TestPage";
//import GoogleTagManager from "./components/GoogleTag";

function App() {
  const [search] = useSearchParams();
  const camp = search.get("camp");

  useEffect(() => {
    if (camp) {
      localStorage.setItem("camp", camp);
    }
  }, [camp]);
  return (
    <>
      <div className="min-h-[100vh] bg-white">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/reservation" element={<ReservationPage />} />
            <Route path="/success-appt" element={<SuccessAppt />} />
            <Route path="/canceled-appt" element={<CancelledAppt />} />
            <Route path="/paiement" element={<PaymentPage />} />
            <Route path="/services" element={<ServicePage />} />
            <Route path="/services/:id" element={<ServiceDetails />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route path="/success-gift" element={<SuccessGift />} />
            <Route path="/canceled-appt" element={<CancelledAppt />} />
            <Route path="/p/:id" element={<PagePrestataire />} />
            <Route path="/shop" element={<ShopPage />} />
            <Route path="/shop/:id" element={<ProductPage />} />
            <Route path="/gift/:id/:duration/:codePromo?" element={<Gift />} />
            <Route path="/forms/:id" element={<Formulaires />} />
            <Route path="/livraison" element={<LivraisonPage />} />
            <Route path="/canceled" element={<LivraisonPage />} />
            <Route path="/page/:id" element={<PagesBlog />} />
            <Route path="/myappt" element={<MyAppts />} />
            <Route path="/orders" element={<OrdersPage />} />
            <Route path="/profil" element={<ProfilPage />} />
            <Route path="/getreview/:id" element={<GetReview />} />
            <Route path="/nos-tarifs" element={<ServicePage />} />
            <Route path="/test" element={<TestPage />} />
            <Route path="/blog" element={<BlogModule />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
          <Route path="/login" element={<NewLoginPage />} />
          <Route path="/resetpassword" element={<ForgotPassword />} />
        </Routes>
      </div>
    </>
  );
}

export default App;

export const Layout = () => {
  console.log("check auth");
  return (
    <>
      <NavBar />
      <Outlet />
      <Footer />
    </>
  );
};
