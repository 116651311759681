import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { useAuth } from "../contexts/AuthContext";
import { sendEmail } from "../utils/mailer";
const NewLoginPage = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const redirect = queryParams.get("redirect");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    lastname: "",
    firstname: "",
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
    phone: "",
  });

  const [mode, setMode] = useState("login");
  const [errors, setErrors] = useState("");
  const [message, setMessage] = useState("");
  const [seePassword, setSeePassword] = useState(false);
  const [isCGVChecked, setIsCGVChecked] = useState(false);
  const [isPromotionsChecked, setIsPromotionsChecked] = useState(false);

  const { login, signup } = useAuth();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // Si le champ modifié est 'email' ou 'confirmEmail', convertir sa valeur en minuscule
    if (name === "email" || name === "confirmEmail") {
      setFormData({ ...formData, [name]: value.toLowerCase() });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);
    if (mode == "signup") {
      if (!formData.email || !formData.password) {
        setErrors("Veuillez remplir tous les champs");
        return;
      }
      if (!formData.email.includes("@") || !formData.email.includes(".")) {
        setErrors("Veuillez entrer un email valide");
        return;
      }
      if (!formData.lastname) {
        setErrors("Veuillez entrer votre nom");
        return;
      }
      if (!formData.firstname) {
        setErrors("Veuillez entrer votre prénom");
        return;
      }
      if (!formData.phone) {
        setErrors("Veuillez entrer votre numéro de téléphone");
        return;
      }
      if (formData.email !== formData.confirmEmail) {
        setErrors("Les emails ne correspondent pas");
        return;
      }
      if (formData.password !== formData.confirmPassword) {
        setErrors("Les mots de passe ne correspondent pas");
        return;
      }
      if (!isCGVChecked) {
        setErrors("Veuillez accepter les CGV");
        return;
      }
      if (!isPromotionsChecked) {
        setErrors("Veuillez accepter de recevoir des offres promotionnelles");
        return;
      }

      // Vérification si l'email existe déjà
      const clientsCol = collection(db, "clients");
      const q = query(clientsCol, where("email", "==", formData.email));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        setErrors("Un compte utilisant cet email existe déjà.");
        return;
      }
      signup(formData.email, formData.password)
        .then((result) => {
          console.log(result);
          const user = result.user;
          const clientCol = collection(db, "clients");
          addDoc(clientCol, {
            email: formData.email,
            adress: "",
            city: "",
            zipcode: "",
            country: "",
            phone: formData.phone || "",
            firstname: formData.firstname || "",
            lastname: formData.lastname || "",
            uid: user.uid,
          });
          // envoie de l'email de confirmation
          const emailData = {
            toEmail: formData.email,
            templateId: "d-345e54ff75f74515a0195f7bd48df89e",
            templateData: {
              title: "Bienvenue chez Tadlik",
              subject: "Bienvenue chez Tadlik",
              text: `Bonjour ${formData.firstname} ${formData.lastname},<br>Bienvenue chez Tadlik !<br>Nous vous remercions pour votre confiance et espérons que vous serez satisfait de nos services.<br>Cordialement,<br>L'équipe Tadlik`,
            },
          };
          console.log(emailData);
          sendEmail(emailData);
          setMessage("Votre compte a bien été créé, veuillez vous connecter");
          setMode("login");
          //navigate("/livraison");
          // rediriger vers la page formulaire adresse de livraison
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          console.log(error);
          setErrors(error.message);
        });
    } else {
      login(formData.email, formData.password)
        .then((result) => {
          console.log(result);
          if (redirect === "livraison") {
            navigate("/livraison");
          } else {
            if (redirect) {
              navigate("/" + redirect);
            } else {
              navigate("/");
            }
          }
          // rediriger vers la page formulaire adresse de livraison
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          console.log(error);
          if (error.message === "Firebase: Error (auth/invalid-credential).") {
            setErrors("Email ou mot de passe incorrect");
          } else {
            setErrors(error.message);
          }
        });
    }
  };

  return (
    <div className="min-h-[100vh] z-10 bg-gray-50">
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Connectez-vous
          </h2>
        </div>
        {errors && (
          <div className="alert alert-error">
            <div className="flex-1">
              <label>{errors}</label>
            </div>
          </div>
        )}
        {message && (
          <div className="alert alert-success">
            <div className="flex-1">
              <label>{message}</label>
            </div>
          </div>
        )}
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form className="space-y-6" onSubmit={(e) => handleSubmit(e)}>
              {mode != "login" && (
                <div>
                  <label
                    htmlFor="lastname"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Nom *
                  </label>
                  <div className="mt-2">
                    <input
                      id="lastname"
                      name="lastname"
                      type="text"
                      required
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 py-1.5 bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              )}
              {mode != "login" && (
                <div>
                  <label
                    htmlFor="firstname"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Prénom *
                  </label>
                  <div className="mt-2">
                    <input
                      id="firstname"
                      name="firstname"
                      type="text"
                      required
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 py-1.5 bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              )}
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email *
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    onChange={handleChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {mode != "login" && (
                <div>
                  <label
                    htmlFor="confirmEmail"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Confirmer l'Email *
                  </label>
                  <div className="mt-2">
                    <input
                      id="confirmEmail"
                      name="confirmEmail"
                      type="text"
                      required
                      onChange={handleChange}
                      className="block w-full rounded-md border-0 py-1.5 bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              )}

              {mode != "login" && (
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Numéro de téléphone *
                  </label>
                  <div className="mt-2">
                    <input
                      id="phone"
                      name="phone"
                      type="text"
                      required
                      onChange={(e) => handleChange(e)}
                      className="block w-full rounded-md border-0 py-1.5 bg-white text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              )}

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Mot de passe *
                </label>
                <div className="mt-2 flex">
                  <input
                    id="password"
                    name="password"
                    type={seePassword ? "text" : "password"}
                    autoComplete="password"
                    onChange={handleChange}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <div className="hover:cursor-pointer hover:text-blue-400 text-black">
                    {seePassword ? (
                      <EyeOffIcon onClick={() => setSeePassword(false)} />
                    ) : (
                      <EyeIcon onClick={() => setSeePassword(true)} />
                    )}
                  </div>
                </div>
              </div>

              {mode != "login" && (
                <div>
                  <label
                    htmlFor="confirmPassword"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Confirmer le mot de passe *
                  </label>
                  <div className="mt-2">
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      autoComplete="confirmPassword"
                      onChange={handleChange}
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              )}

              <div className="flex flex-col gap-4">
                {mode != "login" && (
                  <div className="flex items-center">
                    <input
                      id="promotions"
                      name="promotions"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 "
                      onChange={() =>
                        setIsPromotionsChecked(!isPromotionsChecked)
                      }
                    />
                    <label
                      htmlFor="cgv"
                      className="ml-3 block text-sm leading-6 text-gray-900"
                    >
                      J'accepte de recevoir des offres promotionnelles de la
                      part de Tadlik
                    </label>
                  </div>
                )}

                {mode != "login" && (
                  <div className="flex items-center">
                    <input
                      id="cgv"
                      name="cgv"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 "
                      onChange={() => setIsCGVChecked(!isCGVChecked)}
                    />
                    <label
                      htmlFor="cgv"
                      className="ml-3 block text-sm leading-6 text-gray-900"
                    >
                      Je reconnais avoir lu et accepté les{" "}
                      <Link
                        to="/page/Tv6AZumqoYrGXFqVQdPz"
                        target="_blank"
                        className="text-blue-600 hover:text-blue-500 underline"
                      >
                        CGV
                      </Link>
                    </label>
                  </div>
                )}
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {mode == "login" ? "Connexion" : "Inscription"}
                </button>
              </div>
            </form>

            <div>
              {mode === "signup" ? (
                <p
                  className="mt-10 text-center text text-black hover:text-gray-600 hover:cursor-pointer"
                  onClick={() => setMode("login")}
                >
                  Vous avez déjà un compte ? Connectez-vous
                </p>
              ) : (
                <p
                  className="mt-10 text-center text text-black hover:text-gray-600 hover:cursor-pointer"
                  onClick={() => setMode("signup")}
                >
                  Créer votre compte
                </p>
              )}
              <div className="mt-4 text-center">
                <Link
                  to="/resetpassword"
                  className="leading-6 text-black hover:text-indigo-500 my-4"
                >
                  Mot de passe oublié ?
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLoginPage;
